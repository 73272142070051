<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <div v-if="user.authenticated">
            <v-form ref="formProfile">
                <v-row justify="center" class="mt-5">
                    <v-col cols="12" lg="10">
                        <v-card class="elevation-5">
                            <v-card-title class="headline font-weight-light">Edit User Profile</v-card-title>
                            <v-card-text>
                                <v-row align="start">
                                    <v-col cols="12" sm="5" md="4" lg="4" xl="3" align="center">
                                        <v-avatar
                                            size="200"
                                            :color="hideAvatarText ? 'transparent' : 'rfaccent2'"
                                            class="elevation-5"
                                        >
                                            <v-img
                                                eager
                                                aspect-ratio="1"
                                                :src="userAvatarSrc"
                                                :alt="user.username + ' Avatar'"
                                                @load="hideAvatarText = true"
                                                @error="hideAvatarText = false"
                                                v-show="hideAvatarText"
                                                ref="userAvatar"
                                            ></v-img>
                                            <span class="white--text white--text display-3 font-weight-thin pt-1"
                                                  v-if="!hideAvatarText">{{ user.callsign | splitCallsign }}</span>
                                        </v-avatar>
                                        <!--                                    <v-btn block color="error" class="mt-2" @click="deleteAvatar">-->
                                        <!--                                        <v-icon>mdi-trash-can</v-icon>-->
                                        <!--                                        Remove-->
                                        <!--                                    </v-btn>-->
                                    </v-col>
                                    <v-col cols="12" sm="7" md="4" lg="4" xl="4">
                                        <v-text-field
                                            v-model="username"
                                            label="Username"
                                            prepend-inner-icon="mdi-account"
                                            :rules="usernameRules"
                                            outlined
                                        ></v-text-field>

                                        <v-text-field
                                            v-model="email"
                                            label="Email Address"
                                            prepend-inner-icon="mdi-email"
                                            :rules="emailRules"
                                            outlined
                                        ></v-text-field>

                                        <v-text-field
                                            v-model="callsign"
                                            label="GMRS Callsign"
                                            prepend-inner-icon="mdi-card-account-details-star"
                                            :rules="callsignRules"
                                            maxlength="7"
                                            @input="callsign = $_.isString(callsign) ? callsign.toUpperCase() : null"
                                            outlined
                                        ></v-text-field>

                                        <v-file-input
                                            v-model="avatar"
                                            accept="image/jpeg"
                                            label="Profile Photo"
                                            placeholder="Choose an image"
                                            prepend-inner-icon="mdi-camera"
                                            prepend-icon=""
                                            :loading="uploading"
                                            outlined
                                            @change="uploadAvatar"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="5">
                                        <div class="ml-5">
                                            <h4 class="title font-weight-regular">Email Subscription Preferences</h4>

                                            <v-switch
                                                v-model="preferences.requests"
                                                inset
                                                color="primary"
                                                label="Repeater Access Requests"
                                                class="ml-5"
                                            >
                                            </v-switch>

                                            <v-switch
                                                v-model="preferences.digest"
                                                inset
                                                color="primary"
                                                label="Weekly Repeater Digest"
                                                class="ml-5"
                                            >
                                            </v-switch>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row justify="center" class="pb-0">
                    <v-col cols="12" lg="10" lgx="6">
                        <v-sheet light class="mb-5 elevation-5">
                            <vue-editor v-model="profile" label="Profile"></vue-editor>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-container class="px-0 mb-10">
                    <v-row justify="center">
                        <v-col cols="12" lg="10">
                            <div class="text-right">
                                <v-btn color="rfaccent2" dark @click="validateForm">Save Profile
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

            <v-dialog v-model="dialogs.verifyLicense.state" persistent width="700">
                <v-toolbar dense dark color="primary">
                    <v-toolbar-title class="subheading">
                        Verify License Details
                    </v-toolbar-title>
                </v-toolbar>
                <v-card tile>

                    <License v-if="dialogs.verifyLicense.license" :key="dialogs.verifyLicense.license.callSign"
                             :callsign="dialogs.verifyLicense.license.callSign"/>
                    <v-card-text>
                        By clicking <strong>CONFIRM LICENSE</strong> below, I certify that I am the license holder or an
                        eligible family member under FCC Part 95 rules authorized to use this license. Unauthorized use
                        of a callsign is against the Terms and Conditions of this website and may subject you to
                        disciplinary measures. Impersonating a licensee may also be a crime and subject you to legal
                        action.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="red" text @click="dialogs.verifyLicense.state = false">Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="updateProfile"
                               :disabled="dialogs.verifyLicense.license && dialogs.verifyLicense.license.licenseStatus !== 'A'">
                            Confirm License
                            <v-icon class="ml-1">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-snackbar
                v-model="snackbar.state"
                :color="snackbar.color"
                :timeout="snackbar.timeout"
                multi-line
                top
            >
                <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
                {{ snackbar.text }}
            </v-snackbar>

        </div>

        <div v-else>
            <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                You must be logged in to edit your profile.
            </v-alert>

            <Login v-on="$listeners"/>
        </div>

    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import Login from '@/components/Login.vue';
    import License from '@/components/License.vue';
    import { VueEditor } from 'vue2-editor';
    import axios from 'axios';
    import md5 from 'md5';
    import { useTitle } from '@vueuse/core';
    import config from '../../config';

    export default {
        props: ['user'],

        components: {
            AdBTWR,
            Login,
            License,
            VueEditor
        },

        data: () => ({
            loading: false,
            uploading: false,
            username: null,
            email: null,
            callsign: null,
            profile: null,
            avatar: null,
            avatarTimestamp: 0,
            hideAvatarText: true,
            preferences: {
                requests: false,
                digest: false
            },
            usernameRules: [
                v => !!v || 'Username is required',
                v => v?.length > 3 || 'Username must contain at least 3 characters',
                v => v?.length < 30 || 'Username must contain no more than 30 characters',
                v => /^[A-Za-z0-9]{3,30}$/i.test(v) || 'Username must contain letters and numbers only',
            ],
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            callsignRules: [
                v => /^[A-Z]{3}\d{4}$/i.test(v) || /^[A-Z]{4}\d{3}$/i.test(v) || 'Callsign format is invalid'
            ],
            dialogs: {
                verifyLicense: {
                    state: false,
                    license: null
                }
            },
            snackbar: {
                state: false,
                color: null,
                timeout: 5000,
                text: null
            }
        }),

        watch: {
            "user.authenticated"() {
                if (this.user.authenticated) {
                    this.loadProfile();
                }
            },
        },

        filters: {
            splitCallsign(str) {
                const regexNew = /^[A-Z]{4}\d{3}$/i;
                const offset = regexNew.test(str) ? 4 : 3;
                return str.substring(0, offset) + '\n' + str.substring(offset);
            }
        },

        computed: {
            userAvatarSrc: {
                get() {
                    return 'https://files.mygmrs.com/avatar/' + this.user.username.toLowerCase() + '?ts=' + this.avatarTimestamp;
                },
                set() {
                    this.avatarTimestamp = Date.now();
                }
            }
        },

        methods: {
            loadProfile() {
                this.username = this.$_.clone(this.user.username);
                this.email = this.$_.clone(this.user.email);
                this.callsign = this.$_.clone(this.user.callsign);
                this.profile = this.$_.clone(this.user.profile);
                this.preferences = this.$_.clone(this.user.preferences);
            },

            updateProfile() {
                this.loading = true;

                let axiosHeaders = {
                    'Content-Type': 'application/json',
                };
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios({
                    method: 'PUT',
                    url: config.API_LOCATION + '/profile/' + this.user.id,
                    data: {
                        username: this.username,
                        email: this.email,
                        callsign: this.callsign,
                        digest: this.preferences.digest ? 1 : 0,
                        requests: this.preferences.requests ? 1 : 0,
                        profile: this.profile
                    },
                    headers: axiosHeaders
                })
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('reloadprofile');

                            this.snackbar.state = false;
                            this.snackbar.color = 'success';
                            this.snackbar.icon = 'mdi-check';
                            this.snackbar.text = 'Your profile has been updated successfully.';
                            this.snackbar.state = true;
                        } else {
                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-alert';
                            this.snackbar.text = 'Your profile has not been updated due to error: ' + response.data.message;
                            this.snackbar.state = true;
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-alert';
                            this.snackbar.text = 'Your profile has not been updated due to error: ' + (err.response.data.message || err.message);
                            this.snackbar.state = true;
                        }
                    })
                    .then(() => {
                        this.loading = false;
                        this.dialogs.verifyLicense.state = false;
                    });
            },

            async validateForm() {
                try {
                    this.loading = true;

                    const formValid = this.$refs.formProfile.validate();

                    const [callsignValid, usernameValid] = await Promise.all([this.validateCallsign(), this.validateUsername()]);

                    // console.log('formValid:', formValid);
                    // console.log('callsignValid:', callsignValid);
                    // console.log('usernameValid:', usernameValid);

                    this.loading = false;

                    if (formValid && callsignValid && usernameValid) this.updateProfile();
                    else if(!formValid) {
                        this.snackbar.state = false;
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-alert';
                        this.snackbar.text = `Please correct the issues marked in red on the form.`;
                        this.snackbar.state = true;
                    }
                } catch (err) {
                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    }

                    this.loading = false;

                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-alert';
                    this.snackbar.text = `An error has occurred: ${err.message}`;
                    this.snackbar.state = true;
                }
            },

            async validateUsername() {
                try {
                    const response = await axios.get(config.API_LOCATION + '/user/' + this.username);

                    if (this.username === this.user.username) {
                        //username is unchanged
                        return true;
                    } else if (response.data.Username) {
                        //Username is taken
                        this.snackbar.state = false;
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-alert';
                        this.snackbar.text = `The username you entered is already in use. Please try a different one.`;
                        this.snackbar.state = true;

                        return false;
                    } else {
                        //Username is available
                        return true;
                    }
                } catch (err) {
                    if (err.response.status === 404) {
                        return true;
                    } else {
                        console.error(err);
                        return false;
                    }
                }
            },

            async validateCallsign() {
                try {
                    const response = await axios.get(config.API_LOCATION + '/license/' + this.callsign);

                    if (this.callsign === this.user.callsign) {
                        //callsign is the same as already assigned, no need to check it (except maybe for validity?)
                        // this.updateProfile();
                        return true;
                    } else if (response.data.user) {
                        //callsign already taken by someone
                        this.snackbar.state = false;
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-alert';
                        this.snackbar.text = `The callsign ${this.callsign} is already taken by the user ${response.data.user}. If you don't recognize this username, please contact support for help reclaiming your callsign.`;
                        this.snackbar.state = true;
                        return false;
                    } else {
                        //callsign is not taken yet, do further validation?
                        if (response.data.licenseStatus !== 'A') {
                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-alert';
                            this.snackbar.text = `The callsign you entered is ${this.lookupLicenseStatus(response.data.licenseStatus).toLowerCase()}. You must renew it or obtain a new license.`;
                            this.snackbar.state = true;
                            return false;
                        } else {
                            //show verify license dialog
                            this.dialogs.verifyLicense.license = response.data;
                            this.dialogs.verifyLicense.state = true;
                            return true;
                        }
                    }
                } catch (err) {
                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    }

                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-alert';
                    this.snackbar.text = `The callsign you entered does not exist in our system yet. Please double check it and try again.`;
                    this.snackbar.state = true;
                    return false;
                }
            },

            uploadAvatar(file) {
                if (this.avatar) {
                    this.uploading = true;

                    let formData = new FormData();
                    formData.append('avatar', file);

                    let axiosHeaders = {
                        'Content-Type': 'multipart/form-data'
                    };
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    axios
                        .post(config.API_LOCATION + '/upload/avatar', formData, {
                            headers: axiosHeaders
                        })
                        .then(response => {
                            if (response.data.success) {
                                //refresh avatars (both on this page and in the navigation drawer
                                this.userAvatarSrc = this.$refs.userAvatar.src + '?ts=' + Date.now();

                                this.$nextTick(() => {
                                    this.$emit('avatarupdate');
                                });

                                //clear avatar field
                                this.avatar = null;

                                this.snackbar.state = false;
                                this.snackbar.color = 'success';
                                this.snackbar.icon = 'mdi-check';
                                this.snackbar.text = 'Your user photo has been updated successfully.';
                                this.snackbar.state = true;
                            }
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 401) {
                                this.$emit('unauthorized');
                            }

                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-alert';
                            this.snackbar.text = `An error occurred while uploading your user photo: ${err}`;
                            this.snackbar.state = true;
                        })
                        .then(() => {
                            this.uploading = false;
                        });
                }
            },

            deleteAvatar() {
                let axiosHeaders = {
                    'Content-Type': 'multipart/form-data'
                };
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .delete(config.API_LOCATION + '/avatar/' + this.user.id, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.data.success) {
                            //refresh avatars (both on this page and in the navigation drawer
                            this.userAvatarSrc = this.$refs.userAvatar.src + '?ts=' + Date.now();

                            this.$nextTick(() => {
                                this.$emit('avatarupdate');
                            });

                            //clear avatar field
                            this.avatar = null;

                            this.snackbar.state = false;
                            this.snackbar.color = 'success';
                            this.snackbar.icon = 'mdi-check';
                            this.snackbar.text = 'Your user photo has been deleted successfully.';
                            this.snackbar.state = true;
                        } else {
                            throw new Error(response.data.message);
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }

                        this.snackbar.state = false;
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-alert';
                        this.snackbar.text = `An error occurred while deleting your user photo: ${err}`;
                        this.snackbar.state = true;
                    });
            },

            lookupLicenseStatus(code) {
                switch (code) {
                    case 'A':
                        return 'Active';
                    case 'E':
                        return 'Expired';
                    case 'C':
                        return 'Cancelled';
                    case 'T':
                        return 'Terminated';
                    default:
                        return 'Unknown';
                }
            },

            md5(str) {
                if (!str) return '';
                return md5(str);
            }
        },

        mounted() {
            useTitle('Edit Profile - myGMRS.com');

            this.loadProfile();
        }
    }
</script>
